<template>
  <div class="pvhFactory">
    <basic-container>
      <div class="layTop">
        <div class="Title" style="margin-bottom:20px;">{{ $t('VENDOR-CERTIFIED FACTORY') }}</div>
      </div>
      <template>
        <avue-crud ref="crud" v-model="obj" :option="setData.tableOpt" :data="tableData" :row-style="rowStyle"
                   :page.sync="page" @current-change="currentChange" @row-update="handleUpdate"
                   @row-del="del(row, index, done)" @row-save="handleSave">

          <template slot="menuLeft" style="width: calc(100% - 80px);">
            <el-row :gutter="20" class="topFromSearch">
              <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                  <span>{{ $t('CertifiedCode') }}:</span>
                  <el-input v-model="codeVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                </el-col>
                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                  <span>{{ $t('SupplierNickname') }}:</span>
                  <el-input v-model="nameVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                </el-col>
                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                  <span id="supplierSpan">{{ $t('Supplier') }}:</span>
                  <el-select v-model="supplierVal.ids" @focus="focusDiv($event)" @blur="blurDiv($event)" multiple
                             collapse-tags placeholder="" style="width:100%;" @change="supplierchang" clearable>
                    <el-option v-for="item in this.vendorData"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                  <span id="allSpan">{{ $t('EmpowerCategory') }}:</span>
                  <el-select v-model="allSectionsVal.ids" @focus="focusDiv($event)" @blur="blurDiv($event)"
                             collapse-tags placeholder="" style="width:100%;" @change="allchang" clearable>
                    <el-option v-for="item in this.vendorvectionData"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                  <span>{{ $t('Country') }}:</span>
                  <el-input v-model="locationVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                </el-col>
              </el-col>
              <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4"
                      style="padding-top: 25px; text-align: right; align-self: flex-end;">
                <el-button :title="$t('Search')" icon="iconfont sousuo" size="mini" type="primary" @click="searchFrom">
                  {{ $t('Search') }}
                </el-button>
                <el-button type="primary" icon="iconfont tianjia" size="mini" :title="$t('New')" plain
                           @click="$refs.crud.rowAdd()"> {{ $t('New') }}
                </el-button>
              </el-col>
            </el-row>
          </template>

          <template slot-scope="{row,index,size,type}" slot="menu">
            <el-button icon="el-icon-edit-outline" type="text" size="medium" :title="$t('Edit')"
                       @click="$refs.crud.rowEdit(row,index)"></el-button>
            <el-button icon="el-icon-delete-solid" type="text" size="medium" :title="$t('Delete')"
                       @click="del(row,index)"></el-button>
            <!--<el-button icon="iconfont icon-vip353" :class="row.status===3?'primary':'no'" type="text" size="medium" :title="$t('Delete')" @click="tracingPur(row,index,size,type)"></el-button>-->
          </template>

          <template slot-scope="{row,index,type}" slot="menuForm">
            <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="type=='add'"
                       @click="$refs.crud.rowSave()">{{ $t("Hold") }}
            </el-button>
            <el-button type="primary" icon="el-icon-circle-check" size="small" v-if="type=='edit'"
                       @click="$refs.crud.rowUpdate()">{{ $t("Hold") }}
            </el-button>
            <el-button icon="el-icon-circle-close" size="small" @click="$refs.crud.closeDialog()">{{ $t("Cancel") }}
            </el-button>
          </template>

        </avue-crud>
      </template>
    </basic-container>
  </div>
</template>
<style>
</style>
<script>
import {certifiedList, delCer, editCer, editStatus, addCer,} from "@/api/supplierCer";
import {ProcessDropDownList, TracingDropDownList, TracingUnifiedIsBool} from "@/api/unified";

export default {
  data() {
    return {
      obj: {},
      codeVal: "",
      nameVal: "",
      supplierVal: {
        ids: [],
      },
      allSectionsVal: {
        ids: [],
      },
      locationVal: "",
      tableData: [],
      vendorData: [],
      dictData: [],
      vendorAddData: [],
      vendorvectionData: [],//供应商类别
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      page: {
        currentPage: 1,
        total: 0,
        layout: "total,pager,prev,next",
        background: true,
        pageSize: 10
      }
    }
  },
  computed: {
    setData() {
      var validateExist = (rule, value, callback) => {
        if ((value != '' || value != null)) {
          var ids = this.obj.id;
          if (this.obj.id == undefined) {
            ids = "";
          }
          TracingUnifiedIsBool("CertifiedNameExist", ids, value).then(resu => {
            var IsExist = resu.data;
            if (!IsExist) {
              callback(new Error(this.$t('NameIsExist')));
            } else {
              callback();
            }
          }).catch(() => {
            callback();
          });
        } else {
          callback();
        }
      };
      var validateCropExist = (rule, value, callback) => {
        if ((value != '' || value != null)) {
          var ids = this.obj.id;
          if (this.obj.id == undefined) {
            ids = "";
          }
          TracingUnifiedIsBool("CertifiedCropExist", ids, value).then(resu => {
            var IsExist = resu.data;
            if (!IsExist) {
              callback(new Error(this.$t('CropIsExist')));
            } else {
              callback();
            }
          }).catch(() => {
            callback();
          });
        } else {
          callback();
        }
      };
      var validateCodeExist = (rule, value, callback) => {
        if ((value != '' || value != null)) {
          var ids = this.obj.id;
          if (this.obj.id == undefined) {
            ids = "";
          }
          TracingUnifiedIsBool("CertifiedCodeExist", ids, value).then(resu => {
            var IsExist = resu.data;
            if (!IsExist) {
              callback(new Error(this.$t('CodeIsExist')));
            } else {
              callback();
            }
          }).catch(() => {
            callback();
          });
        } else {
          callback();
        }
      };
      //如果所属于最后一级可见下级可为空
      var validateRequired = (rule, value, callback) => {
        var VSections = this.obj.vendorSections.toString();
        value = value.toString();
        if (this.obj.vendorSections == undefined) {
          VSections = "";
        }
        TracingUnifiedIsBool("AllowedVendorSectionsRequired", VSections, value).then(resu => {
          var IsExist = resu.data;
          if (IsExist) {
            callback(new Error(this.$t('Required')));
          } else {
            callback();
          }
        }).catch(() => {
          callback();
        });
      };
      return {
        tableOpt: {
          page: true,
          refreshBtn: false,
          emptyText: this.$t('No Data'),
          menuTitle: this.$t('OPERATION'),
          menuWidth: 140,
          align: 'center',
          columnBtn: false,
          simplePage: false,
          editTitle: this.$t('Edit'),
          addTitle: this.$t('Add'),
          addBtn: false,
          editBtn: false,
          delBtn: false,
          header: true,
          labelPosition: 'top',
          saveBtn: false,
          updateBtn: false,
          cancelBtn: false,
          column: [
            {
              //认证工厂的编码编码
              label: this.$t('CertifiedCode'),
              prop: 'code',
              placeholder: ' ',
              rules: [{required: true, message: this.$t('Required'), trigger: "blur"},
                {validator: validateCodeExist, trigger: "blur"}

              ]
            },
            //{
            //    //认证工厂的等级
            //    label: this.$t('GRADE'),
            //    prop: 'grade',
            //    type: "rate",
            //    hide: true,
            //    texts: [this.$t('grade1'), this.$t('grade2'), this.$t('grade3'), this.$t('grade4'), this.$t('grade5')],
            //    colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
            //    rules: [{
            //        required: true,
            //        message: this.$t('Required'),
            //        trigger: "blur"
            //    }]
            //},
            {
              label: this.$t('Supplier'),
              prop: 'corporationId',
              type: "select",
              placeholder: ' ',
              filterable: true,
              hide: true,
              dicData: this.vendorData,
              rules: [{required: true, message: this.$t('Required'), trigger: "blur"},
                {required: true, validator: validateCropExist, trigger: "blur"}]
            },
            {
              //工厂名称
              label: this.$t('SupplierNickname'),
              prop: 'certifiedName',
              placeholder: ' ',
              rules: [{required: true, message: this.$t('Required'), trigger: "blur"},
                {required: true, validator: validateExist, trigger: "blur"}]
            },
            {
              label: this.$t('ADDRESS'),
              prop: 'vendorAddressId',
              type: "select",
              filterable: true,
              dicData: this.vendorAddData,
              placeholder: ' ',
              rules: [{required: true, message: this.$t('Required'), trigger: "blur"}]
            },
            {
              //所属类别
              label: this.$t('BelongingCategory'),
              type: "checkbox",
              prop: 'vendorSections',
              labelTip: this.$t('BelongingCategory'),
              formslot: true,
              filterable: true,
              placeholder: ' ',
              dicData: this.vendorvectionData,
              rules: [{
                required: true,
                message: this.$t('Required'),
                trigger: "blur"
              }]
            }
            ,
            {
              //认证工厂的编码编码
              label: this.$t('EmpowerCategory'),
              prop: 'allowedVendorSections',
              type: "checkbox",
              placeholder: ' ',
              labelTip: this.$t('EmpowerCategory'),
              formslot: true,
              filterable: true,
              dicData: this.vendorvectionData,
              rules: [{
                required: true,
                validator: validateRequired,
                trigger: "blur"
              }]
            },
          ]
        }
      }
    }
  },
  created() {
    this.getcerlist();
    this.init()
  },
  methods: {
    init() {
      ProcessDropDownList('CorporationListNoBrand').then(res => {
        this.vendorData = res.data;
      });
      ProcessDropDownList('DictList', 'brand').then(res => {
        this.dictData = res.data;
      });
      TracingDropDownList('VendorSectionList').then(res => {
        this.vendorvectionData = res.data;

      });
      TracingDropDownList('VendorAddress').then(res => {
        this.vendorAddData = res.data;
      });
    },
    focusDiv(obj) {//搜索框获取焦点
      if (obj.currentTarget === undefined) {
        obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
      } else {
        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
      }
    },//搜索框失去焦点
    blurDiv(obj) {
      if (obj.currentTarget === undefined) {
        if (obj.value === "") {
          obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
        }
      } else if (obj.currentTarget === null) {
        if (this.supplierVal.ids.length === 0) {
          document.querySelector("#supplierSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
        }
        if (this.allSectionsVal.ids.length === 0) {
          document.querySelector("#allSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
        }
      } else {
        if (obj.target.value == "") {
          obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
        }
      }
    },
    supplierchang() {
      if (this.supplierVal.ids.length === 0) {
        document.querySelector("#supplierSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
      } else {
        document.querySelector("#supplierSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
      }
    },//
    allchang() {
      if (this.allSectionsVal.ids.length === 0) {
        document.querySelector("#allSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
      } else {
        document.querySelector("#allSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
      }
    },//
    rowStyle({rowIndex}) {
      if (rowIndex % 2 === 0) {
        return {
          backgroundColor: '#F8F8F8'
        }
      }
    },//切换分页
    currentChange(val) {
      this.page.currentPage = val;
      this.getcerlist();
    },//搜索
    searchFrom() {
      this.getcerlist();
    },//编辑数据
    handleUpdate(row, index, done) {
      row.allowedVendorSections = row.allowedVendorSections.toString();
      row.vendorSections = row.vendorSections.toString();
      editCer(row).then(() => {
        this.$message({showClose: true, message: this.$t('UpdateSuccess'), type: "success"});
        this.getcerlist();
        this.obj = {};
      })
      done();
    },
    //},//删除数据
    del(row, index, done) {
      this.$confirm(
          this.$t('IsDel') + row.certifiedName, this.$t('Tips'), {
            confirmButtonText: this.$t('Yes'),
            cancelButtonText: this.$t('Cancel'),
            type: "warning"
          }).then(() => {
        delCer(row.id).then(() => {
          this.getcerlist();
          this.$message({showClose: true, message: this.$t('DeleteSuccess'), type: "success"});
          done();
        })
      })
    },
    //显示数据
    async getcerlist() {
      await certifiedList(this.codeVal, this.nameVal, this.supplierVal.ids.toString(), this.allSectionsVal.ids.toString(), this.locationVal, this.page.currentPage, this.page.pageSize).then(res => {
        var cardata = res.data.certifiedVendors;
        for (var i = 0; i < cardata.length; i++) {
          if (cardata[i].allowedVendorSections != null) {
            var allVendorSectionids = cardata[i].allowedVendorSections.split(',');
            cardata[i].allowedVendorSections = allVendorSectionids;
          }
          if (cardata[i].vendorSections != null) {
            var vendorSectionss = cardata[i].vendorSections.split(',');
            cardata[i].vendorSections = vendorSectionss;
          }

        }
        this.tableData = cardata;
        this.page.total = res.data.totalCount;
        this.page.pageSize = res.data.pageSize;
      }).catch(erro => {
        console.log(erro);
      })
    },
    //认证状态
    tracingPur(row) {
      this.$message({showClose: true, message: this.$t('online'), type: "warning"});
      // editStatus(row);
    },
    //新增数据 按照认证信息添加
    handleSave(row, done) {
      row.allowedVendorSections = row.allowedVendorSections.toString();
      row.vendorSections = row.vendorSections.toString();
      addCer(row).then(() => {
        this.$message({showClose: true, message: this.$t('AddSuccess'), type: "success"});
        this.getcerlist();
        this.obj = {};
        done();
      }).catch(() => {
      });
    },
  }
}
</script>